@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;500;600;700;800&family=Courier+Prime:wght@700&family=Titillium+Web:wght@500;700&display=swap');
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body{
  background-color: #202633;
}
